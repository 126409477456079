<script>
  import { onMount, afterUpdate } from 'svelte'
  import classNames from 'classnames/bind'
  import { Bus, TOOLTIP_SHOW_REQUEST } from '@qlean/web-core'
  import { Caption } from '@qlean/ui-kit-web-svelte'
  import { afterMount } from '@qlean/web-widget-sdk/build/Client'

  import css from './_styles.scss'

  const cN = classNames.bind(css)

  const selfId = `tooltip_self`
  const contentId = `tooltip_content`
  const arrowId = `tooltip_arrow`

  let isVisible = false
  let isPreparing = true
  let text
  let top = 0
  let left = 0
  let arrowShift = 0
  let self
  let arrowWidth
  let content
  let targetParams
  let isAbove
  let isBelow

  afterUpdate(() => {
    if (self === undefined || text === undefined || targetParams === undefined) {
      return
    }

    const {
      top: targetTop,
      left: targetLeft,
      width: targetWidth,
      height: targetHeight,
    } = targetParams

    const { width, height } = self.getBoundingClientRect()
    const { width: contentWidth } = content.getBoundingClientRect()

    if (arrowWidth === undefined) {
      arrowWidth = document.getElementById(arrowId).getBoundingClientRect().width
    }

    const canShowAbove = targetTop >= height
    const canShowBelow = targetTop + targetHeight + height <= document.documentElement.clientHeight

    if (!canShowAbove || canShowBelow) {
      isAbove = false
      isBelow = true
      top = targetTop + targetHeight
    } else {
      isAbove = true
      isBelow = false
      top = targetTop - height
    }

    const targetCenterLeft = targetLeft + targetWidth / 2

    if (targetCenterLeft < width / 2) {
      left = 0
      arrowShift = Math.max(targetCenterLeft - width / 2, -contentWidth / 2 + arrowWidth * 1.5)
    } else if (targetCenterLeft > document.documentElement.clientWidth - width / 2) {
      left = document.documentElement.clientWidth - width
      arrowShift = Math.min(
        targetCenterLeft - document.documentElement.clientWidth + width / 2,
        contentWidth / 2 - arrowWidth * 1.5
      )
    } else {
      left = targetCenterLeft - width / 2
    }

    top += window.pageYOffset
    left += window.pageXOffset

    isPreparing = false
  })

  onMount(() => {
    self = document.getElementById(selfId)
    content = document.getElementById(contentId)

    afterMount()

    Bus.on(TOOLTIP_SHOW_REQUEST, ({ text, target }) => {
      show({ text, target })
    })
  })

  function show({ text: nextText, target }) {
    isPreparing = true
    targetParams = target.getBoundingClientRect()
    text = nextText
    isVisible = true
  }

  function hide() {
    isVisible = false
    text = undefined
    arrowShift = 0
    targetParams = undefined
    isAbove = false
    isBelow = false
  }

  $: style = `top: ${top}px; left: ${left}px;`
</script>

<svelte:window on:click|capture={hide} on:resize={hide} on:scroll={hide} />

<div
  class={cN('tooltip', {
    'tooltip--visible': isVisible,
    'tooltip--preparing': isPreparing,
    'tooltip--above': isAbove,
    'tooltip--below': isBelow,
  })}
  {style}
  id={selfId}>
  <div class={cN('tooltip__arrow')} style={`--arrow-shift: ${arrowShift}px;`} id={arrowId} />
  <div class={cN('tooltip__content')} id={contentId}>
    {#if text !== undefined}
      <Caption>{text}</Caption>
    {/if}
  </div>
</div>
